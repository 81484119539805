<template>
	<div class="wrap">
		<div class="header">发布产品</div>
		<SeCate v-show="!isForm" @eventCateList="handleGetCate" />
		<FillForm v-show="isForm" :cate="cate" @eventToCate="handleToCate" @eventSubmit="handleSubmit" />
	</div>
</template>


<script>
	import SeCate from '@/components/Product/SeCate'
	import FillForm from '@/components/Product/FillForm'
	import Axios from 'axios'
	export default {
		data() {
			return {
				isForm: false,
				cate: {
					id: [],
					name: []
				}
			}
		},
		components: {
			SeCate,
			FillForm
		},
		created() {
			if (sessionStorage.getItem('unfinishedForm')) {
				this.isForm = true
			}else{
				this.isForm = false
			}
		},
		methods: {
			handleGetCate(data) {
				this.cate = data
				this.isForm = true
			},
			handleToCate() {
				this.isForm = false
			},
			handleSubmit(form) {
				form.user_id=this.$store.state.SELLER_ID;
				Axios.post('/addGoods',this.$qs.stringify(form)).then(data => {
					if (data.data.code == 200) {
						this.$alert(data.data.msg, '提示', {
							type: 'success',
							callback: action => {
								sessionStorage.removeItem('unfinishedForm')
								this.$router.push('/product')
							}
						})
					}else{
						this.$alert(data.data.msg, '提示', {
							type: 'error',
							callback: action => {
								// this.$router.push('/product')
							}
						})
					}
				})
			}
		},
		// destroyed() {
		// 	sessionStorage.removeItem('unfinishedForm')
		// },
	}
</script>

<style scoped>
	.wrap{
		width: 1600px;
		background-color: #fff;
	}
	.header {
		padding: 0 28px;
		height: 77px;
		border-bottom: 1px solid #e0e0e0;
		display: flex;
		font-size: 18px;
		color: #000000;
		align-items: center;
		font-weight: 700;
	}

	.header::before {
		content: '';
		width: 4px;
		height: 20px;
		background: #0e69db;
		margin-right: 10px;
	}

	.content {
		padding: 0 28px;
		color: #333;
	}
</style>
